.fw-help-icon {
  color: $color-warning-dark-1;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  @media (min-width: $desktop-mode-min) {
    display: block;
    position: absolute;
  }
}

.fw-popover-tooltip {
  background: $color-neutral-80;
  color: $color-neutral-0;
  z-index: 100;
  @include border-radius(3px);
  max-width: 350px;
  width: 350px;
  @media (max-width: $mobile-mode-max) {
    position: relative;
    max-width: none;
    width: 100%;
  }
  &.popover.bottom > .arrow {
    border-bottom-color: $color-neutral-80;

    &:after {
      border-bottom-color: $color-neutral-80;
    }
  }
  &.collapse > .arrow, &.collapsing > .arrow {
    position: fixed;
  }
  &.fw-popover-tooltip--hidden.collapse:not(.in) {
    visibility: hidden;
    height: 0;
  }
}

.fw-attachment-extensions-help-icon {
  vertical-align: middle;
  font-size: 22px;
  right: -39px;
  @media (max-width: 991px) {
    margin-left: 15px;
  }
}

.fw-attachment-tooltip {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  &.popover.right > .arrow {
    border-right-color: $color-neutral-80;

    &:after {
      border-right-color: $color-neutral-80;
    }
  }
  .fw-attachment-tooltip-list {
    padding: 0;
    margin-left: 17px;
  }
}
